<template>
	<v-snackbar v-model="show" :timeout="timeout" :color="color">
		<v-row dense align="center">
			<v-col>
				{{ message }}
			</v-col>
			<v-col v-if="dismissible" cols="1" align="end">
				<v-icon @click="show = false"> mdi-close </v-icon>
			</v-col>
		</v-row>
	</v-snackbar>
</template>

<script>
import { mapWritableState } from 'pinia';
import { snackbarStore } from '@/store/snackbar';
export default {
	computed: {
		...mapWritableState(snackbarStore, [
			'color',
			'dismissible',
			'message',
			'show',
			'timeout',
		]),
	},
};
</script>

<style>
.v-snackbar__wrapper {
	margin-bottom: 67px!important;
}
</style>

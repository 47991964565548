import { CapacitorUpdater } from '@capgo/capacitor-updater';
import safeStringify from 'fast-safe-stringify';
import appInsights from '@/AppInsights';

export function checkForUpdate() {
	console.warn('Checking for update');
	CapacitorUpdater.notifyAppReady();
}

export function getDeviceId() {
	return CapacitorUpdater.getDeviceId();
}

const listeners = [
	'appReloaded',
	'downloadFailed',
	'updateFailed',
	'majorAvailable',
	'downloadComplete',
	'updateAvailable',
	'noNeedUpdate',
	'download',
];
for (let index = 0; index < listeners.length; index++) {
	const listener = listeners[index];
	CapacitorUpdater.addListener(listener, (state) => {
		appInsights.trackTrace({
			message: `updater.${listener}`,
		});
		appInsights.trackEvent({
			name: `updater.${listener}`,
			properties: {
				...JSON.parse(safeStringify(state)),
			},
		});
	});
}
window.getLatestVersion = CapacitorUpdater.getLatest;

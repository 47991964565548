// @ts-nocheck
import miscAxios from '@/assets/js/axios/miscAPI';
import { defineStore } from 'pinia';

export const maintenanceModeStore = defineStore('maintenanceMode', {
	state: () => {
		return {
			status: '',
		};
	},
	actions: {
		async load() {
			miscAxios.get('/meta/maintenanceMode/shooter/').then(({ data }) => {
				this.status = data;
			});
		},
	},
});

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import * as labsComponents from 'vuetify/labs/components'

// Vuetify
import { createVuetify } from 'vuetify';

export default createVuetify({
	components: {
		...labsComponents
	},
	theme: {
		defaultTheme: 'straightScore',
		themes: {
			straightScore: {
				dark: false,
				colors: {
					primary: '#008AB8',
					secondary: '#b0bec5',
					accent: '#606C6A',
					error: '#F15B2A',
					dark: '#393C53',
					info: '#38A2BC',
					success: '#4BAA4E',
					warning: '#FFB100',
				},
			},
			dark: {
				dark: true,
				colors: {
					primary: '#008AB8',
					secondary: '#b0bec5',
					accent: '#606C6A',
					error: '#F15B2A',
					dark: '#393C53',
					info: '#38A2BC',
					success: '#4BAA4E',
					warning: '#FFB100',
				},
			},
		},
	},
});

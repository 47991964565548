import axios from 'axios';
import App from './App.vue';
import VueGtag from 'vue-gtag';
import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import VueConfetti from 'vue-confetti';
import router from './router/index.js';
import vuetify from './plugins/vuetify';
import appInsights from '@/AppInsights';
import { alertStore } from '@/store/alert';
import { confirmStore } from '@/store/confirm';
import { createPinia, mapActions } from 'pinia';
import { version, hash } from '../package.json';
import { snackbarStore } from '@/store/snackbar';
import { loadFonts } from './plugins/webfontloader';
import { progressBarStore } from '@/store/progressBar';
import shooterAxios from '@/assets/js/axios/shooterSideAPI';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
import localStorageWrapper from '@/capacitor/localStorageWrapper';

const snackbarPinia = mapActions(snackbarStore, [
	'showSnackbar',
	'hideSnackbar',
]);
const alertPinia = mapActions(alertStore, ['showAlert']);
const confirmPinia = mapActions(confirmStore, ['showAlert']);
const progressBarPinia = mapActions(progressBarStore, [
	'showProgressBar',
	'hideProgressBar',
	'changeColor',
	'toggleProgressBar',
]);

const app = createApp(App);

/*****************************************
 | 			Force enable DevTools		 |
 *****************************************/
/* eslint-disable storage/no-localstorage*/
app.config.devtools =
	window.location.href.includes('//localhost:') ||
	window.location.href.includes('//next.') ||
	window.location.href.includes('//app.') ||
	window.location.href.includes('//nice-meadow-') ||
	(localStorage.enableVueDevTools &&
		localStorage.enableVueDevTools === 'maitai');
/* eslint-enable storage/no-localstorage*/

loadFonts();

app.use(VueConfetti);

//Axios modules
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$shooter = shooterAxios;
app.config.globalProperties.$localStorage = localStorageWrapper;
app.config.globalProperties.$appInsights = appInsights;

//Custom functions
app.config.globalProperties.$snackbar = {
	/**
	 * Displays the snackbar
	 * @param {Object} opts
	 * @param {String} opts.text
	 * @param {Number} opts.timeout
	 * @param {String} opts.color
	 * @param {Boolean} opts.dismissible
	 */
	show: snackbarPinia.showSnackbar,
	hide: snackbarPinia.hideSnackbar,
};

/**
 * Displays the snackbar
 * @param {Object} opts
 * @param {String} opts.text
 * @param {String} opts.title
 * @param {String} opts.buttonText
 * @param {Number} opts.titleColor effects the header color (optional)
 * @param {Boolean} opts.persistent
 */
app.config.globalProperties.$alert = alertPinia.showAlert;

/**
 * Displays the snackbar
 * @param {Object} opts
 * @param {String} opts.text
 * @param {String} opts.title
 * @param {String} opts.yesButtonText
 * @param {String} opts.noButtonText
 * @param {String} opts.yesButtonColor
 * @param {String} opts.noButtonColor
 * @param {Number} opts.titleColor effects the header color (optional)
 * @param {Boolean} opts.persistent
 */
app.config.globalProperties.$confirm = confirmPinia.showAlert;

app.config.globalProperties.$progressBar = {
	toggle: progressBarPinia.toggleProgressBar,
	show: progressBarPinia.showProgressBar,
	hide: progressBarPinia.hideProgressBar,
	changeColor: progressBarPinia.changeColor,
};

window.alerts = {
	$alert: alertPinia.showAlert,
	$confirm: confirmPinia.showAlert,
	$snackbar: {
		show: snackbarPinia.showSnackbar,
		hide: snackbarPinia.hideSnackbar,
	},
	$progressBar: {
		toggle: progressBarPinia.toggleProgressBar,
		show: progressBarPinia.showProgressBar,
		hide: progressBarPinia.hideProgressBar,
		changeColor: progressBarPinia.changeColor,
	},
};

if (import.meta.env.VITE_SENTRY_DSN)
	Sentry.init({
		app,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		ignoreErrors: [
			'Request failed with status code 416',
			'Request failed with status code 406',
			'Request failed with status code 402',
			'Request failed with status code 401',
			'AxiosError: Network Error',
			'chrome-extension://',
			'cancelled',
			/\.at/gim,
		],
		tags: {
			git_commit: hash,
		},
	});

console.info(`Version: ${version}@${hash}`);

//Start the app
app
	.use(router)
	.use(autoAnimatePlugin)
	.use(createPinia())
	.use(vuetify)
	.use(
		VueGtag,
		{
			config: {
				id: import.meta.env.VITE_GOOGLE_ANALYTICS,
				pageTrackerScreenviewEnabled: true,
			},
		},
		router
	)
	.mount('#app');

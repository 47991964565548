export default [
	{
		name: 'Edit Profile',
		path: '/account/edit',
		component: () => import('../views/UserAccount/EditProfile.vue'),
		meta: {
			public: false,
		},
	},
];

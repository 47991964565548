import { Preferences } from '@capacitor/preferences';
export default {
	async set(key, value) {
		const v = JSON.stringify(value);

		return Preferences.set({
			key: key,
			value: v,
		});
	},
	async get(key) {
		const { value } = await Preferences.get({ key: key });
		if (value === null) return value;
		return JSON.parse(value);
	},
	async remove(key) {
		return Preferences.remove({ key: key });
	},
	async delete(key) {
		return Preferences.remove({ key: key });
	}
};

import appInsights from '@/AppInsights';

export const consoleLogHeaders = (prefix) => {
	/**
	 * If `x-warn` or `x-debug` is set by the server it will be logged to console with prefix
	 * @param {Response} response
	 */
	return (response) => {
		if (!response.headers) return;
		if (response.headers['x-warn']) {
			console.warn(`[${prefix}]`, res.headers['x-warn']);
			appInsights.trackTrace({
				message: `api-header-warn`,
				severityLevel: 0,
				properties: {
					res: res.headers['x-warn'],
				},
			});
		}
		if (response.headers['x-debug']) {
			console.debug(`[${prefix}]`, res.headers['x-debug']);
			appInsights.trackTrace({
				message: `api-header-debug`,
				severityLevel: 0,
				properties: {
					res: res.headers['x-debug'],
				},
			});
		}
	};
};

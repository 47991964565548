export default [
	{
		name: 'Sign up',
		path: '/signup',
		component: () => import('../views/Authentication/SignUp.vue'),
		meta: {
			public: true,
			fullWidth: true,
		},
	},
	{
		name: 'Resend Validation',
		path: '/resend-validate-email',
		component: () =>
			import('../views/Authentication/Resend-Validate-Email.vue'),
		meta: {
			public: true,
			fullWidth: true,
		},
	},
	{
		name: 'Validate Email',
		path: '/validate-email',
		component: () => import('../views/Authentication/Validate-Email.vue'),
		meta: {
			public: true,
			fullWidth: true,
		},
	},
	{
		name: 'Login',
		path: '/login',
		component: () => import('../views/Authentication/Login.vue'),
		meta: {
			public: true,
			fullWidth: true,
		},
	},
	{
		name: 'Logout',
		path: '/logout',
		component: () => import('../views/Authentication/Logout.vue'),
		meta: {
			public: true,
			fullWidth: true,
		},
	},
	{
		name: 'Terms of Service',
		path: '/terms-of-service',
		component: () => import('../views/Authentication/TOS.vue'),
		meta: {
			public: true,
			fullWidth: true,
		},
	},
	{
		name: 'Privacy Policy',
		path: '/privacy-policy',
		component: () => import('../views/Authentication/PrivacyPolicy.vue'),
		meta: {
			public: true,
			fullWidth: true,
		},
	},
	{
		name: 'Reset Password',
		path: '/reset-password',
		component: () => import('../views/Authentication/ResetPassword.vue'),
		meta: {
			public: true,
			fullWidth: true,
		},
	},
	{
		name: 'Forgot Password',
		path: '/forgot-password',
		component: () => import('../views/Authentication/ForgotPassword.vue'),
		meta: {
			public: true,
			fullWidth: true,
		},
	},
];

export const encode = (plainText) => {
	return plainText
		.split('')
		.map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
		.join('');
};

export const decode = (hex) => {
	return hex
		.split(/(\w\w)/g)
		.filter((p) => !!p)
		.map((c) => String.fromCharCode(parseInt(c, 16)))
		.join('');
};

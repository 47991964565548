// @ts-nocheck
import { defineStore } from 'pinia';
//Used to allow the parent to resolve the promise

// You can name the return value of `defineStore()` anything you want, but it's best to use the name of the store and surround it with `use` and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const progressBarStore = defineStore('progressBar', {
	// arrow function recommended for full type inference
	state: () => {
		return {
			show: false,
			color: 'white',
		};
	},
	actions: {
		showProgressBar(color = 'white') {
			this.show = true;
			this.color = color;
		},
		hideProgressBar() {
			this.show = false;
		},
		toggleProgressBar() {
			this.show = !this.show;
		},
		changeColor(color = 'white') {
			this.color = color;
		},
	},
});

// @ts-nocheck
import { defineStore } from 'pinia';

// You can name the return value of `defineStore()` anything you want, but it's best to use the name of the store and surround it with `use` and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const snackbarStore = defineStore('snackbar', {
	// arrow function recommended for full type inference
	state: () => {
		return {
			show: false,
			timeout: 5000,
			color: undefined,
			message: '',
			dismissible: false,
		};
	},
	actions: {
		/**
		 * Displays the snackbar
		 * @param {Object} opts
		 * @param {String} opts.text
		 * @param {Number} opts.timeout
		 * @param {String} opts.color
		 * @param {Boolean} opts.dismissible
		 */
		async showSnackbar(opts) {
			if (typeof opts === 'string') {
				this.message = opts;
			} else {
				this.message = opts.text;
				this.timeout = opts.timeout || 5000;
				this.color = opts.color || undefined;
				this.dismissible = !!opts.dismissible;
			}
			this.show = true;
		},
		async hideSnackbar() {
			this.show = false;
		},
	},
});

<template>
	<PiniaSnackbar />
	<PiniaAlert />
	<PiniaConfirm />
</template>

<script>
import PiniaSnackbar from '@/components/PiniaModals/piniaSnackbar.vue';
import PiniaAlert from '@/components/PiniaModals/piniaAlert.vue';
import PiniaConfirm from '@/components/PiniaModals/piniaConfirm.vue';
export default {
	name: 'PiniaModalsWrapper',
	components: {
		PiniaSnackbar,
		PiniaAlert,
		PiniaConfirm,
	},
};
</script>

<style></style>

export const encodeBase64 = (data) => {
	const utf8Bytes = new TextEncoder().encode(data);
	return btoa(String.fromCharCode(...utf8Bytes));
};

export const decodeBase64 = (data) => {
	const binaryString = atob(data);
	const bytes = new Uint8Array(binaryString.length);
	for (let i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	return new TextDecoder().decode(bytes);
};

export default {
	encodeBase64,
	decodeBase64,
};

<template>
	<v-app>
		<div v-if="$route.meta && $route.meta.layout === 'fullScreen'">
			<router-view v-slot="{ Component, route }" :key="$route.fullPath">
				<MaintenanceMode />
				<v-slide-x-transition>
					<div :key="route.fullPath">
						<component :is="Component" />
					</div>
				</v-slide-x-transition>
			</router-view>
		</div>
		<Public v-else-if="$route.meta.public">
			<router-view v-slot="{ Component, route }" :key="$route.fullPath">
				<MaintenanceMode />
				<v-slide-x-transition>
					<div :key="route.fullPath">
						<component :is="Component" />
					</div>
				</v-slide-x-transition>
			</router-view>
		</Public>
		<Authorized v-else-if="!$route.meta.public">
			<router-view v-slot="{ Component, route }" :key="$route.fullPath">
				<MaintenanceMode />
				<v-slide-x-transition>
					<div :key="route.fullPath">
						<component :is="Component" />
					</div>
				</v-slide-x-transition>
			</router-view>
		</Authorized>
		<AllPiniaModals />
	</v-app>
</template>

<script>
import Public from './layouts/Public.vue';
import { mapActions, mapState } from 'pinia';
import { authorizationStore } from '@/store/authorization';
import Authorized from './layouts/Authorized.vue';
import AllPiniaModals from './components/PiniaModals/allPiniaModals.vue';
import { Capacitor } from '@capacitor/core';
import { checkForUpdate, getDeviceId } from '@/capacitor/autoUpdater';
import appUpdateAxios from '@/assets/js/axios/appUpdate';
import MaintenanceMode from './components/maintenanceMode.vue';
export default {
	name: 'App',

	components: {
		Public,
		Authorized,
		AllPiniaModals,
		MaintenanceMode,
	},

	data: () => ({}),

	computed: {
		...mapState(authorizationStore, ['userId']),
	},

	async created() {
		await this.checkAppInitState();
		this.initAuth().then((isAuthorized) => {
			if (!isAuthorized) {
				console.warn('[Init] Not authorized.');
			}
		});

		window.addEventListener('keydown', (ev) => {
			if (ev.altKey && ev.key === '[') {
				this.$router.push('/debug');
			}
		});
		checkForUpdate();
	},

	methods: {
		...mapActions(authorizationStore, {
			initAuth: 'init',
		}),
		async checkAppInitState() {
			const native = Capacitor.isNativePlatform();
			if (native) {
				window.deviceId = (await getDeviceId()).deviceId;
				checkForUpdate();
			}
		},
	},
};
</script>
<style>
html {
	touch-action: manipulation;
}
</style>

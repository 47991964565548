<template>
	<v-dialog
		v-model="show"
		:transition="topDown ? 'dialog-top-transition' : 'dialog-bottom-transition'"
		:persistent="persist"
	>
		<v-card min-width="50vw">
			<v-toolbar v-show="!!header" :color="titleColor" style="width: 100%">
				<span class="text-h5 mx-2 px-3">
					{{ header }}
				</span>
			</v-toolbar>
			<v-card-text :style="header ? 'margin-top: 64px' : ''">
				<div class="text-body-1">
					<div
						v-if="bodyText.startsWith('html...')"
						v-html="bodyText.replace('html...', '')"
					/>
					<span v-else>
						{{ bodyText }}
					</span>
				</div>
			</v-card-text>
			<v-card-actions>
				<v-btn text :color="noButtonColor" @click="close(false)">
					{{ noButtonText }}
				</v-btn>
				<v-spacer />
				<v-btn :color="yesButtonColor" @click="close(true)">
					{{ yesButtonText }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapWritableState } from 'pinia';
import { confirmStore } from '@/store/confirm';
export default {
	computed: {
		...mapWritableState(confirmStore, [
			'yesButtonText',
			'noButtonText',
			'yesButtonColor',
			'noButtonColor',
			'show',
			'bodyText',
			'header',
			'topDown',
			'titleColor',
			'persist',
			'p',
		]),
	},
	watch: {
		show(v) {
			//Reverse fly out direction from the fly-in direction
			if (v) {
				this.$nextTick(() => {
					this.topDown = !this.topDown;
				});
			}
		},
	},
	methods: {
		close(v) {
			this.p.resolve(v);
			this.show = false;
		},
	},
};
</script>

<style></style>

<template>
	<v-layout class="bg">
		<v-main
			:class="`mx-auto ${
				$route.meta.fullWidth || $vuetify.display.width <= 1140
					? ''
					: 'limitMaxWidth'
			}`"
		>
			<v-container class="pb-0">
				<v-card class="mt-6 pa-3 rounded-xl">
					<v-img src="/assets/spclogo.webp" height="50px" />
				</v-card>
			</v-container>
			<slot />
		</v-main>
	</v-layout>
</template>

<script>
export default {
	data() {
		return {};
	},
	methods: {},
};
</script>

<style scoped>
@media (min-width: 1441px) {
	.limitMaxWidth {
		max-width: 1440px;
		/* box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
			0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%); */
	}
}
.bg {
	background: url('/assets/bg.webp');
	background-size: cover;
}
</style>

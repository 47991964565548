import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import $shooterAPI from '@/assets/js/axios/shooterSideAPI';
import $localStorage from './localStorageWrapper';
import { authorizationStore } from '@/store/authorization';

const isNative = Capacitor.isNativePlatform();

async function registerToken(token) {
	const info = await Device.getInfo();
	$shooterAPI
		.post('/users/fcm-token', {
			deviceType: Capacitor.getPlatform(),
			fcmToken: token,
			deviceDetails: info,
		})
		.then(({ data }) => {
			console.log('data', data);
			$localStorage.set('pushNotificationResponse', data);
			$localStorage.get(getShortKey(token), 'registered-in-cloud');
		});
}

// https://capacitorjs.com/docs/apis/push-notifications
if (isNative) {
	PushNotifications.addListener('registration', async ({ value }) => {
		let token = value;

		if (await $localStorage.get(getShortKey(token))) {
			//Notifications registered
			return;
		}

		registerToken(token);

		$localStorage.set('pushNotificationToken', token);
	});
} else {
	console.info('ℹ Not native, push notifications listener disabled');
}

function getShortKey(token) {
	return (
		token.substring(0, 3) +
		token.substring(
			Math.floor(token.length / 2),
			Math.floor(token.length / 2) + 3
		) +
		token.substring(token.length - 3, token.length)
	);
}

export const registerNotifications = async (allowPrompt = false) => {
	if (!isNative) {
		return;
	}
	const authStore = authorizationStore();
	if (!authStore.isLoggedIn()) {
		console.error(
			'You should not be registering notifications if the user is not signed in'
		);
		return;
	}

	let permStatus = await PushNotifications.checkPermissions();

	if (permStatus.receive === 'prompt' && allowPrompt) {
		permStatus = await PushNotifications.requestPermissions();
	}

	if (permStatus.receive !== 'granted') {
		$localStorage.set('pushNotificationGrantStatus', permStatus.receive);
		return;
	}

	await PushNotifications.register();
	$localStorage.set('pushNotificationGrantStatus', 'granted');
};

export const getStatus = async () => {
	if (!isNative) {
		return 'not-available';
	}
	return PushNotifications.checkPermissions();
};

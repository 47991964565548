<template>
	<v-expand-transition>
		<v-container v-show="maintenanceModeStore.status" class="mb-n4 px-5">
			<v-alert
				type="info"
				color="yellow-lighten-3"
				class="font-weight-bold text-h6 pa-n2"
				style="border: 3px dashed black"
			>
				We are currently performing maintenance, some functionality may be
				temporarily unavailable.
			</v-alert>
		</v-container>
	</v-expand-transition>
</template>

<script>
import { mapStores } from 'pinia';
import { maintenanceModeStore } from '../store/maintenanceMode';

export default {
	name: 'MaintenanceMode',

	computed: {
		...mapStores(maintenanceModeStore),
	},

	mounted() {
		this.maintenanceModeStore.load();
	},
};
</script>

import { authorizationStore } from '@/store/authorization';
import { consoleLogHeaders } from './common';
import axios from 'axios';
import appInsights from '@/AppInsights';
import localStorageWrapper from '@/capacitor/localStorageWrapper';

const endpoints = {
	local: 'http://localhost:4000',
	next: 'https://api-shooter-next.shootpro.cloud',
	prod: 'https://api-shooter.shootpro.cloud',
};

const headerLog = consoleLogHeaders('SP-API');

const shooterAxios = axios.create();

shooterAxios.interceptors.request.use(async function (config) {
	const useDevServer =
		(await localStorageWrapper.get('useNextServer')) ||
		window.location.host.includes('localhost:3254') ||
		window.location.host.includes('localhost:5173') ||
		window.location.host.includes('nice-meadow') ||
		window.location.host.includes('beta') ||
		(window.location.host.includes('next') &&
			!window.location.host.includes('next-spc-app'));
	config.baseURL = useDevServer ? endpoints.next : endpoints.prod;
	if (
		window.location.href.includes('useLocalhost=true') ||
		sessionStorage['useLocalhost']
	) {
		if (!window.shownLocalWarning) {
			console.warn(
				"USING LOCAL FROM SESSIONSTORAGE. RUN `delete sessionStorage['useLocalhost']` TO USE DEFAULT SERVER"
			);
			window.shownLocalWarning = true;
		}
		config.baseURL = endpoints.local;
		sessionStorage['useLocalhost'] = true;
	}
	const authStore = authorizationStore();
	if (authStore.token) {
		config.headers.Authorization = `Bearer ${authStore.token}`;
	}
	config.headers['x-from-service'] = 'shooter-side-ui';
	return config;
});

shooterAxios.interceptors.response.use(
	function (res) {
		headerLog(res);

		return res;
	},
	function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		if (
			error &&
			error.response &&
			error.response.status !== 401 &&
			error.response.status !== 402 &&
			error.response.status !== 406 &&
			error.response.status !== 416
		)
			console.error('[SP-API HTTP ERROR]', error.response || error);
		else console.warn('[SP-API HTTP WARNING]', error.response || error);

		if (error.response) headerLog(error.response);
		let errorTracker = {
			errorMessage: `${error}`,
			hasResponse: !!error.response,
		};

		if (errorTracker.hasResponse) {
			errorTracker.response = {
				headers: error.response.headers,
				data: error.response.data || 'null',
				statusCode: error.response.status,
			};
		}

		appInsights.trackTrace({
			message: 'axios.reject.shooter.side.api',
			severityLevel: 3,
			properties: errorTracker,
		});

		if (!error.response) {
			appInsights.trackException({
				exception: error,
				properties: {
					type: 'shooter-side-axios-call-nonhttp',
				},
			});
		}

		return Promise.reject(error);
	}
);

localStorageWrapper.get('useNextServer').then((x) => {
	if (x) {
		console.warn('Moving to next endpoint!');
		shooterAxios.defaults.baseURL = endpoints.next;
	}
});

export default shooterAxios;

export default [
	{
		name: 'View Current/Past Shoots',
		path: '/view/shoots',
		component: () => import('../views/public_landing/ShootList.vue'),
		meta: {
			public: true,
		},
	},
	{
		name: 'View Scores',
		path: '/view/club/:clubId/shoot/:shootId/scores',
		component: () => import('../views/ShootViewer/ViewScores.vue'),
		meta: {
			public: true,
		},
	},
	{
		name: 'View Winners',
		path: '/view/club/:clubId/shoot/:shootId/event-winners',
		component: () => import('../views/ShootViewer/ViewShootOffs.vue'),
		meta: {
			public: true,
		},
	},
];

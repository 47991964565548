// @ts-nocheck
import { defineStore } from 'pinia';
import $localStorage from '@/capacitor/localStorageWrapper';
import shooterAxios from '@/assets/js/axios/shooterSideAPI';
import { registerNotifications } from '@/capacitor/pushNotifications';
import appUpdateAxios from '@/assets/js/axios/appUpdate';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { decodeBase64, encodeBase64 } from '@/assets/js/base64';

async function getDeviceData() {
	const d = await Device.getInfo();
	let ret = {
		androidSDKVersion: d.androidSDKVersion,
		iOSVersion: d.iOSVersion,
		manufacturer: d.manufacturer,
		model: d.model,
		isVirtual: d.isVirtual,
		name: d.name,
		operatingSystem: d.operatingSystem,
		osVersion: d.osVersion,
		platform: d.platform,
		webViewVersion: d.webViewVersion,
	};
	switch (Capacitor.getPlatform()) {
		case 'android':
			delete ret.iOSVersion;
			break;
		case 'ios':
			delete ret.androidSDKVersion;
			break;
		default:
			break;
	}
	return ret;
}

String.prototype.crypt = function () {
	const that = this;
	let output = '';
	for (let i = that.length - 1; i >= 0; i--) {
		let character = that[i];
		if (character == character.toLowerCase()) {
			// The character is lowercase
			output += character.toUpperCase();
		} else {
			// The character is uppercase
			output += character.toLowerCase();
		}
	}
	return output;
};

// You can name the return value of `defineStore()` anything you want, but it's best to use the name of the store and surround it with `use` and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const authorizationStore = defineStore('authorization', {
	// arrow function recommended for full type inference
	state: () => {
		return {
			initialized: false,
			token: '',
			userId: '',
			user: '',
			lastRefresh: null,
		};
	},
	actions: {
		async logout() {
			await shooterAxios.post('/users/logout').finally(async () => {
				this.token = null;
				await $localStorage.remove('user.token');
				window.dispatchEvent(new Event('logout'));
			});
		},
		async login(username, password, captchaToken) {
			const body = {
				captchaToken,
			};

			const { data } = await shooterAxios.post('/users/login', body, {
				auth: {
					username: username.trim(),
					password: password,
				},
			});
			this.user = data;
			this.userId = data.userId;
			this.token = data.token;
			this.lastRefresh = new Date();
			this.user.accountValidated = !!this.user.accountValidated;
			await $localStorage.set('user.id', this.userId);
			await $localStorage.set(
				'user.token',
				encodeBase64(this.token.crypt()).crypt()
			);
			await $localStorage.set('appInitialized', true);
			await registerNotifications(false);

			if (window.deviceId) {
				appUpdateAxios
					.post('/device/register', {
						deviceId: window.deviceId,
						userId: this.user.userId,
						deviceDetails: {
							platform: Capacitor.getPlatform(),
							...(await getDeviceData()),
						},
					})
					.catch((err) => {
						console.error('Error registering device', err.response || err);
					});
			}
		},
		async init() {
			const userToken = await $localStorage.get('user.token');
			if (userToken) {
				try {
					this.token = decodeBase64(userToken.crypt()).crypt();
				} catch {
					//Token is malformed / invalid
					await $localStorage.remove('user.token');
					this.initialized = true;
					return false;
				}

				//Validate the token (not really needed, but is a quick way to check)
				const tokenValid = this.validateToken(this.token);
				if (!tokenValid) {
					await $localStorage.remove('user.token');
					this.token = null;
					this.initialized = true;
					return false;
				}

				//Token is valid get the user's details
				await this.refreshUserDetails();
				this.userId = this.user.userId;
				this.lastRefresh = new Date();
				this.initialized = true;
				registerNotifications(false);
				console.log('Authorization Initialized Successfully!');
				if (window.deviceId && !window.deviceIdRegistered)
					appUpdateAxios
						.post('/device/register', {
							deviceId: window.deviceId,
							userId: this.user.userId,
							deviceDetails: {
								platform: Capacitor.getPlatform(),
								...(await getDeviceData()),
							},
						})
						.then(() => {
							window.deviceIdRegistered = true;
						});
				return true;
			}
			this.initialized = true;
			return false;
		},
		async validateToken(token) {
			if (token === undefined) {
				token = this.token;
			}
			if (!this.token) {
				this.token = null;
				await $localStorage.remove('user.token');
				return false;
			}
			try {
				const { data } = await shooterAxios.get(`/users/verify/token/${token}`);
				return data.valid;
			} catch (e) {
				console.error(e);
				return false;
			}
		},
		isLoggedIn() {
			return !!this.token;
		},
		async refreshUserDetails() {
			//Token is valid get the user's details
			await shooterAxios
				.get('/users')
				.then(({ data }) => {
					this.user = data;
					if (this.user.userId) {
						this.userId = this.user.userId;
					}
				})
				.catch((err) => {
					if (err.response && err.response.status === 401) {
						if (err.response.data.error === 'Token is invalid') {
							this.logout();
							if (!window.location.href.includes('/login'))
								window.location.href = '/login';
						}
					}
				});
		},
	},
});
